import './_BuyPage.scss'
import Header from "../../header/Header";
import Footer from "../../footer/Footer";
// import shopPage from "../../../assets/images/product-mob.png";
import foto1 from "../../../assets/images/1.png";
import foto2 from "../../../assets/images/2.png";
import foto3 from "../../../assets/images/3.png";
import foto4 from "../../../assets/images/4.png";
import foto5 from "../../../assets/images/5.png";
import foto6 from "../../../assets/images/6.png";


import shopPageMobNew from "../../../assets/images/Hero-mob-product.png";
import tooltip from "../../../assets/images/Group 219.svg";
import {useEffect, useState} from "react";
import {
    Checkbox,
    FormControlLabel,
    FormGroup,
    styled,
    TextField,
    Tooltip,
    tooltipClasses,
    Typography
} from "@mui/material";

const BuyPage = () => {
    const [count, setCount] = useState(1);
    const [totalPrice, setTotalPrice] = useState(349);
    const [acceptPrivacy, setAcceptPrivacy] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [foto, setFoto] = useState([foto1,foto2,foto3,foto4,foto5, windowWidth > 826 ? foto6 : foto6]);



    // const [blockDimensions, setBlockDimensions] = useState({ width: 515, height: 735 });
    //
    // useEffect(() => {
    //     const handleResize = () => {
    //
    //         const windowWidth = window.innerWidth;
    //         const windowHeight = window.innerHeight;
    //
    //
    //         const aspectRatio = 123 / 160;
    //
    //         const minBlockWidth = 108;
    //         const maxBlockWidth = 627;
    //         const minBlockHeight = minBlockWidth / aspectRatio;
    //         const maxBlockHeight = maxBlockWidth / aspectRatio;
    //
    //         let newBlockWidth = minBlockWidth;
    //         let newBlockHeight = minBlockHeight;
    //
    //         if (windowWidth < 320) {
    //             newBlockWidth = minBlockWidth;
    //         } else if (windowWidth > 1000) {
    //             newBlockWidth = maxBlockWidth;
    //         } else {
    //             const widthScaleFactor = (windowWidth - 20) / (1000 - 320);
    //             newBlockWidth = minBlockWidth + widthScaleFactor * (maxBlockWidth - minBlockWidth);
    //         }
    //
    //         newBlockHeight = newBlockWidth / aspectRatio;
    //
    //         if (newBlockHeight > windowHeight) {
    //             newBlockHeight = windowHeight;
    //             newBlockWidth = newBlockHeight * aspectRatio;
    //         }
    //
    //         setBlockDimensions({ width: newBlockWidth, height: newBlockHeight });
    //     };
    //
    //     handleResize();
    //     window.addEventListener('resize', handleResize);
    //
    //     return () => window.removeEventListener('resize', handleResize);
    // }, []);

    console.log(acceptPrivacy)
    const addCount = () => {
        if (count < 5) {
            setCount(count + 1)
            setTotalPrice(totalPrice + 349)
        }
    }
    const removeCount = () => {
        if (count > 1) {
            setCount(count - 1)
            setTotalPrice(totalPrice - 349)
        }
    }
    const HtmlTooltip = styled(({className, ...props}) => (
        <Tooltip placement="left-start" {...props} classes={{popper: className}}/>
    ))(({theme}) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: 'unset',
            position: 'relative',
            bottom: '30px',
            left: '-5px'
        },
    }));
    const changeImg = (fromIndex, toIndex) => {
        const arr = [...foto]
        if (fromIndex < 0 || fromIndex >= arr.length || toIndex < 0 || toIndex >= arr.length) {
            throw new Error('Invalid index');
        }
        const element = arr.splice(fromIndex, 1)[0];
        arr.splice(toIndex, 0, element);
        setFoto(arr)
    }

    const backgroundImageStyle = {
        backgroundImage: `url(${foto[4]})`
    };
    const [open, setOpen] = useState(false);

    const handleMouseEnter = () => {
        setOpen(true);
    };

    const handleMouseLeave = () => {
        setOpen(false);
    };

    return (<>
            <Header white={false} fixed={false}/>
            <div className='shop-wrapper'>
                <div className='left-box' >
                        <div className='product-box'  >
                            <img src={foto[5]} alt="product"/>
                        </div>
                    <div className='left-cubes'>
                        {foto?.map((item,index)=> {
                         return  index < 5 && <div key={index} onClick={()=>changeImg(index,5)}><img src={item}/></div>
                        })}
                    </div>
                </div>
                <div className='right-box'>
                    <div className='box-shop'>
                        <h5 className='breadcrumbs'><a href="/">Home</a> / <span>Product page</span></h5>

                        <div className='box-product'>
                            <div  className='bg-img'>
                                <div>
                                    <img src={foto[5]} alt="product"/>
                                </div>
                                {/*<img src={WindowWidth > 500 ? shopPageMob:shopPageMobNew} style={{ width: `${blockDimensions.width}px`, height: `${blockDimensions.height}px`}} alt=""/>*/}
                            </div>
                            <div className='cubes-mob'>
                                {foto?.map((item,index)=> {
                                    return  index < 5 && <div key={index} onClick={()=>changeImg(index,5)}><img src={item}/></div>
                                })}
                            </div>
                        </div>
                        <div className='title-and-price'>
                            <h2>Pivot Hawk</h2>
                            <div className='price'>
                                per device / <span>$349</span>
                            </div>
                        </div>

                        <p className='description'>We're offering the  <b>ALPHA version</b> of our PivotHawk device to cover
                            development expenses. To show our appreciation for early support, we're providing a
                            special discount. Take advantage of this opportunity to experience our innovative
                            solution at a reduced price!</p>
                    </div>
                    <div className='box-shop-next'>
                        <div className='right-content'>
                            <div className='select-qty'>
                                <div><span>
                                    <HtmlTooltip open={open} title={<> <img className={'tooltip-img'} src={tooltip} alt=""/>  </>}>
                                        <img onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}
                                            src="data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM8 4C7.46459 4 7.04623 4.46229 7.0995 4.99504L7.45025 8.50248C7.47849 8.78492 7.71616 9 8 9C8.28384 9 8.52151 8.78492 8.54975 8.50248L8.9005 4.99504C8.95377 4.46228 8.53541 4 8 4ZM8.00154 10C7.44926 10 7.00154 10.4477 7.00154 11C7.00154 11.5523 7.44926 12 8.00154 12C8.55383 12 9.00154 11.5523 9.00154 11C9.00154 10.4477 8.55383 10 8.00154 10Z' fill='%23B60000'/%3E%3C/svg%3E%0A"
                                            alt="icon"/>
                                      </HtmlTooltip>
                                    Do you have a light bulb socket on your pivot?</span></div>
                                <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>yes</div>
                                <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>no</div>
                                <div>Quantity <div>{count}</div></div>
                                <div onClick={addCount}>+</div>
                                <div onClick={removeCount}>-</div>
                            </div>
                            {/*<div className='field-form'>*/}
                            {/*    <TextField*/}
                            {/*        sx={{width: '100%', padding: '10px 0'}}*/}
                            {/*        id="standard-helperText"*/}
                            {/*        label="Bussiness or private name"*/}
                            {/*        // required*/}
                            {/*        // helperText="Enter your login"*/}
                            {/*        variant="standard"*/}
                            {/*        // defaultValue={credentials.email}*/}
                            {/*        onChange={(e) => console.log(123)}*/}
                            {/*    />*/}
                            {/*    <TextField*/}
                            {/*        sx={{width: '100%', padding: '10px 0'}}*/}
                            {/*        id="standard-helperText"*/}
                            {/*        label="Delivery address (street, apt, city, province, zip)"*/}
                            {/*        // required*/}
                            {/*        // helperText="Enter your login"*/}
                            {/*        variant="standard"*/}
                            {/*        // defaultValue={credentials.email}*/}
                            {/*        onChange={(e) => console.log(123)}*/}
                            {/*    />*/}
                            {/*    <TextField*/}
                            {/*        sx={{width: '100%', padding: '10px 0'}}*/}
                            {/*        id="standard-helperText"*/}
                            {/*        label="Delivery Instructions"*/}
                            {/*        // required*/}
                            {/*        // helperText="Enter your login"*/}
                            {/*        variant="standard"*/}
                            {/*        // defaultValue={credentials.email}*/}
                            {/*        onChange={(e) => console.log(123)}*/}
                            {/*    />*/}
                            {/*    <TextField*/}
                            {/*        sx={{width: '100%', padding: '10px 0'}}*/}
                            {/*        id="standard-helperText"*/}
                            {/*        label="Phone number"*/}
                            {/*        // required*/}
                            {/*        // helperText="Enter your login"*/}
                            {/*        variant="standard"*/}
                            {/*        // defaultValue={credentials.email}*/}
                            {/*        onChange={(e) => console.log(123)}*/}
                            {/*    />*/}
                            {/*    <TextField*/}
                            {/*        sx={{width: '100%', padding: '10px 0'}}*/}
                            {/*        id="standard-helperText"*/}
                            {/*        label="Email"*/}
                            {/*        // required*/}
                            {/*        // helperText="Enter your login"*/}
                            {/*        variant="standard"*/}
                            {/*        // defaultValue={credentials.email}*/}
                            {/*        onChange={(e) => console.log(123)}*/}
                            {/*    />*/}

                            {/*</div>*/}
                            <div className='description-price'>
                                <div className='item-box'>
                                    <div className='one-item'>
                                        <div className='title-p'>
                                            <h3> Service fee</h3>
                                            <div className='count-title'> year per device / <span>$0</span></div>
                                        </div>

                                        <p> Affordable, comprehensive access to platform features, updates, and support
                                        </p>
                                    </div>
                                    <div className='two-item'>
                                        <div className='count-title'> year per device / <span>$0</span></div>
                                        <div className='count-box'>
                                            <div className='count-box-item'>
                                                <div> Quantity</div>
                                                <div>{count}</div>
                                                <div className='plus' onClick={addCount}>+</div>
                                            </div>
                                            <div className='minus'>
                                                <div onClick={removeCount}>-</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='total-box'>
                                <div className='accept'>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    sx={{
                                                        color: '#000',
                                                        fontSize: 28,
                                                        '&.Mui-checked': {
                                                            color: '#010036',
                                                        },
                                                    }}
                                                    onChange={(e) => setAcceptPrivacy(e.target.checked)}
                                                />
                                            }
                                            label={
                                                <Typography
                                                    component="span"
                                                    dangerouslySetInnerHTML={{ __html: 'I agree with <a style="text-decoration: none;\n' +
                                                            '    color: #010036;" href="/privacy">policies and agreements</a>' }}
                                                />
                                            }
                                        />
                                    </FormGroup>

                                </div>
                                <div className='total'>
                                    <div className='total-price'>
                                        <div>total: <span>${totalPrice}</span></div>
                                    </div>
                                    <a  href={acceptPrivacy && `https://pivothawk.com/step/?add-to-cart=12&quantity=${count}`}
                                       className={`btn-buy ${!acceptPrivacy && 'disable'}`}>Checkout</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    );
};

export default BuyPage
