import './_Location.scss'
import Header from "../../header/Header";
import Footer from "../../footer/Footer";
import maps from "../../../assets/images/maps.png"

const Location = () => {

    return (<>
            <Header/>
            <div className='location-box'>
                <div className='container'>
                    <div className='crumbs'>
                        <p><a href="/">Home</a> / <span>Location</span></p>
                    </div>
                    <h2>You can buy our devices here</h2>

                    <div>
                        <h4>PivotHawk Gem, AB</h4>
                        <p>163077B Township Rd 232, Newell County No. 4,<br/>
                            AB T0J 1M0, Canad
                            <br/>
                            <span><a className='link' href="tel:14036331796">+14036331796</a></span>
                        </p>
                        <img src={maps} alt="maps"/>
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    );
};

export default Location
